import React,{useState} from 'react';
import { Button, Col, Form, Input, Row } from 'antd';
import { useDispatch, useSelector } from 'react-redux';

const OffsetField = ({ label, field, resolution, range, charLength, mode, error,format }) => {
  const dispatch = useDispatch();
  const value = useSelector((state) => state.lorwan[field]);
  const [inputValue, setInputValue] = useState(value); 

  const handleIncrement = () => {
    updateFieldValue(resolution);
  };

  const handleDecrement = () => {
    updateFieldValue(-resolution);
  };

// const updateFieldValue = (change) => {
//   // Extract the current numeric value, keeping the sign if present
//   let currentValue = parseFloat(value.replace(/[^\d.-]/g, '')) || 0;

//   // Update value based on resolution
//   let newValue = currentValue + change;

//   // Ensure new value is within the specified range
//   newValue = Math.max(range[0], Math.min(newValue, range[1]));

//   // Determine the sign (+ or -) based on the updated value
//   const sign = newValue < 0 ? '-' : '+';

//   // Format the value with the appropriate decimal places
//   const decimalPlaces = resolution.toString().split('.')[1]?.length || 0;
//   const absoluteValue = Math.abs(newValue).toFixed(decimalPlaces);

//   // Pad the value if needed and prepend the sign
//   const formattedValue = `${sign}${absoluteValue.padStart(charLength - 1, '0')}`;

//   // Dispatch the updated value
//   dispatch({ type: `UPDATE_LORWAN_${field}`, payload: formattedValue });
// };

const updateFieldValueInput = (inputValue) => {
  // Remove any non-numeric characters except for the sign (+ or -) and decimal point
  let cleanedValue = inputValue.replace(/[^\d.-]/g, '');
  
  
  
  
  // Check if it's a valid number or empty input
  if (!isNaN(cleanedValue) || cleanedValue === '' || cleanedValue === '-' || cleanedValue === '+') {
    let numericValue = parseFloat(cleanedValue) || 0; // Extract numeric value or fallback to 0
    
    // Ensure value is within the specified range
    let newValue = Math.max(range[0], Math.min(numericValue, range[1]));

    // Format the value as per the 'format' prop
    const hasSign = format.includes('+'); // Check if the format requires a sign
    const [integerPart, decimalPart] = format.replace('+', '').split('.');
    const integerDigits = integerPart.length; // Number of digits before the decimal
    const decimalDigits = decimalPart ? decimalPart.length : 0; // Number of digits after the decimal

    // Format the value with appropriate padding and sign
    let formattedValue = Math.abs(newValue)
      .toFixed(decimalDigits) // Set the required number of decimal places
      .padStart(integerDigits + (decimalDigits > 0 ? decimalDigits + 1 : 0), '0'); // Pad integer part

    // Add sign if format requires it and it's not excluded for specific fields
    if (hasSign && !field.includes('Ro') && !field.includes('occupancy')) {
      const sign = newValue < 0 ? '-' : '+';
      formattedValue = `${sign}${formattedValue}`;
    }

    // Dispatch the updated formatted value
    dispatch({ type: `UPDATE_LORWAN_${field}`, payload: formattedValue });
  }
};

 const updateFieldValue = (change) => {
    // Extract the current numeric value, keeping the sign if present
    let currentValue = parseFloat(value.replace(/[^\d.-]/g, '')) || 0;

    // Update value based on resolution
    let newValue = currentValue + change;

    // Ensure new value is within the specified range
    newValue = Math.max(range[0], Math.min(newValue, range[1]));

    // Determine the formatting details from the `format` prop
    const hasSign = format.includes('+'); // Check if the format requires a sign
    const [integerPart, decimalPart] = format.replace('+', '').split('.');
    const integerDigits = integerPart.length; // Number of digits before the decimal
    const decimalDigits = decimalPart ? decimalPart.length : 0; // Number of digits after the decimal

    // Format the value with appropriate padding and sign
    let formattedValue = Math.abs(newValue)
      .toFixed(decimalDigits) // Set the required number of decimal places
      .padStart(integerDigits + (decimalDigits > 0 ? decimalDigits + 1 : 0), '0'); // Pad integer part

    // Add sign if format requires it and it's not excluded for specific fields
    if (hasSign && !field.includes('Ro') && !field.includes('occupancy')) {
      const sign = newValue < 0 ? '-' : '+';
      formattedValue = `${sign}${formattedValue}`;
    }

    // Dispatch the updated value
    setInputValue(formattedValue);
    dispatch({ type: `UPDATE_LORWAN_${field}`, payload: formattedValue });
  };


 const handleInputChange = (e) => {
  let rawValue = e.target.value;
  // Ensure only one + or - at the beginning and allow only numbers and decimals after
  rawValue = rawValue.replace(/[^+\-.\d]/g, ''); // Remove all characters except +, -, ., and numbers
  // If the input contains a '+' or '-' sign anywhere except the first character, remove it
  if (rawValue.length > 1 && (rawValue.slice(1).includes('+') || rawValue.slice(1).includes('-'))) {
    return; // Exit if + or - sign appears after the first character
  }
  // Handle cases where + or - should affect the charLength
  let charLength = format.length;
  if (format.includes('+') && (rawValue.includes('+') || rawValue.includes('-'))) {
    charLength = format.length; // If the format includes a + sign, allow the full format length
  } else if (format.includes('+')) {
    charLength = charLength - 1; // Reduce by 1 if the format includes + but it's not used
  }

  // Restrict the input length based on charLength
  if (rawValue.length <= charLength) {
    setInputValue(rawValue); // Update local state with user input if length is valid
  }
};


  const handleBlur = () => {
    let cleanedValue = inputValue.replace(/[^\d.-]/g, ''); // Clean the input

    // Check if it's a valid number or empty input
    if (!isNaN(cleanedValue) || cleanedValue === '' || cleanedValue === '-' || cleanedValue === '+') {
      let numericValue = parseFloat(cleanedValue) || 0; // Extract numeric value or fallback to 0

      // Ensure value is within the specified range
      let newValue = Math.max(range[0], Math.min(numericValue, range[1]));

      // Format the value as per the 'format' prop
      const hasSign = format.includes('+'); // Check if the format requires a sign
      const [integerPart, decimalPart] = format.replace('+', '').split('.');
      const integerDigits = integerPart.length; // Number of digits before the decimal
      const decimalDigits = decimalPart ? decimalPart.length : 0; // Number of digits after the decimal

      // Format the value with appropriate padding and sign
      let formattedValue = Math.abs(newValue)
        .toFixed(decimalDigits) // Set the required number of decimal places
        .padStart(integerDigits + (decimalDigits > 0 ? decimalDigits + 1 : 0), '0'); // Pad integer part

      // Add sign if format requires it and it's not excluded for specific fields
      if (hasSign && !field.includes('Ro') && !field.includes('occupancy')) {
        const sign = newValue < 0 ? '-' : '+';
        formattedValue = `${sign}${formattedValue}`;
      }

      // Dispatch the updated formatted value
      dispatch({ type: `UPDATE_LORWAN_${field}`, payload: formattedValue });

      // Update the local input value with the formatted value
      setInputValue(formattedValue);
    }
  };


  return (
    <Col span={24}>
      <Form.Item className='settingfield' label={label}>
        <Row gutter={16}>
          <Col span={6}>
            <Button
              disabled={mode === 'disabled'}
              onClick={handleDecrement}
              className='btnChannel'
            >
              -
            </Button>
          </Col>
          <Col span={12}>
            <Input
              value={inputValue}
              className='textCenter'
              status={error && error[field] ? 'error' : ''}
                onChange={handleInputChange} // Handle input changes
              onBlur={handleBlur} // Format on blur event
              // onChange={(e) => updateFieldValueInput(e.target.value)}
              disabled={mode === 'disabled'}
              
            />
          </Col>
          <Col span={6}>
            <Button
              disabled={mode === 'disabled'}
              onClick={handleIncrement}
              className='btnChannel'
            >
              +
            </Button>
          </Col>
        </Row>
      </Form.Item>
    </Col>
  );
};

export default OffsetField;
