import React,{useEffect, useState} from "react";
import { useSelector, useDispatch } from 'react-redux';
import { Button, Collapse, Modal, Row,Col, Select } from 'antd';
import LorwanTab from './LorwanTab';
import GeneralTab from './GeneralTab';
import { PlusOutlined,MinusOutlined } from '@ant-design/icons';
import { ActionsContext } from '../../contexts/context';
import WriteLanding from  '../Scanner/Writer/index'
import { Input, Form } from 'antd';
import { useNavigate } from "react-router-dom";
import { createTemplate,getTemplate, updateDeviceOffset } from "./actionMethods";
import NetworkSetting from "./NetworkSetting";
import CalibrationSetting from "./CalibrationSetting";
import OffsetSetting from "./OffsetSetting";
import { LoadingOutlined } from '@ant-design/icons';
import { Flex, Spin } from 'antd';
const { Panel } = Collapse;

function Settings() {
  const {Option} = Select;
  const navigate=useNavigate()
  const lorwanState = useSelector((state) => state.lorwan);
  const calibrationState = useSelector((state) => state.calibration);
  const generalState = useSelector((state) => state.scan);
  const thresholdState = useSelector((state) => state.threshold);
  const dispatch = useDispatch();
  const [actions, setActions] = useState(null);
  const {scan, write} = actions || {};
  const [valueData,SetValueData] =useState({
    
  })
  const [settingValue,SetSettingValue] = useState('')
  
  const actionsValue = {actions,setActions};
  const [nfcSettingData,SetNfCSetting] = useState({});
  const [nfcScan,SetNFcScan]= useState([])
  const [successModalVisible, setSuccessModalVisible] = useState(false);
  const [successModalVisibleWarn, setSuccessModalVisibleWarn] = useState(false);
  const [successModalVisibleError, setSuccessModalVisibleError] = useState(false);
  const [secondModalVisible, setSecondModalVisible] = useState(false);
  const [finalValue,SetFinalValue] = useState();
  const [title,SetTitle] =useState();
  const [thirdModal,SetThirdModal]= useState(false);
  const [modalTitle,SetModalTitle]= useState(false);
  const [error,SetError]= useState({});
  const [modalSaveError,SetModalSaveError]= useState(false);
  let userDetails=  localStorage.getItem('userLoggedinSafeAirConfig')?JSON.parse(localStorage.getItem('userLoggedinSafeAirConfig')):{};
  const savedTemplate = localStorage.getItem('nfcTemplate')?JSON.parse(localStorage.getItem('nfcTemplate')):[]
  const [listTemplate,SetListTemplate] = useState([]);
  const [loader,SetLoader]= useState(true);
  const [errorModal,SetErrorModal]= useState(false);
  const [titleMessage,SetTitleMessage] = useState('Scanning for Write...');
  const [logger,SetLogger]= useState({});

  
  const [selectTemplate,setTemplateSelected] = useState('')

  function extractSSID(str) {
    
    const length = parseInt(str.substring(0, 2), 10);
    const secondPart = str.split(".");
    const ssid = secondPart && secondPart[1]? secondPart[1].substring(0,length):" - ";
    return ssid.replace(/\*+$/, '');
}

  const handleLorwanFieldChange = (field, value) => {
    dispatch({ type: `UPDATE_LORWAN_${field.toUpperCase()}`, payload: value });
  };
     let sequnece = [
    { id: 1, key: "deviceEui", required: false, validation: "hex", length: 16 },
    { id: 2, key: "appEui", required: false, validation: "hex", length: 32 },
    { id: 3, key: "applicationKey", required: true, validation: "hex", length: 32 },
    { id: 4, key: "appPort", required: true, validation: "number", length: 3 },
    { id: 5, key: "joinType", required: true, validation: "number", length: 1 },
    { id: 6, key: "networkSessionKey", required: false, validation: "hex", length: 32 },
    { id: 7, key: "applicationSessionKey", required: false, validation: "hex", length: 32 },
    { id: 8, key: "workMode", required: true, validation: "number", length: 1 },
    { id: 9, key: "region", required: true, validation: "number", length: 1 },
    { id: 10, key: "rx2DataRate", required: true, validation: "number", length: 2 },
    { id: 11, key: "rxChannel", required: false, validation: "number", length: 2 },
    { id: 12, key: "spreadingFactor", required: true, validation: "number", length: 2 },
    { id: 13, key: "txPower", required: true, validation: "number", length: 2 },
    { id: 14, key: "adrmode", required: true, validation: "number", length: 1 },
    { id: 15, key: "reportingInterval", required: true, validation: "number", length: 4 },
    { id: 16, key: "confirmed_mode", required: true, validation: "number", length: 1 },
    { id: 17, key: "confirmed_mode_retries", required: true, validation: "number", length: 1 },
    { id: 18, key: "data_storage", required: true, validation: "number", length: 1 },
    { id: 19, key: "data_retransmission", required: true, validation: "number", length: 1 },
    { id: 20, key: "offsetTemp", required: true, validation: "numberString", length: 5 },
    { id: 21, key: "offsetHumidity", required: true, validation: "numberString", length: 3 },
    { id: 22, key: "offsetCo2", required: true, validation: "numberString", length: 5 },
    { id: 23, key: "model_number", required: false, validation: "string", length: 5 },
    { id: 24, key: "firm_ver_number", required: false, validation: "string", length: 5 },
    { id: 25, key: "lora_ver_number", required: false, validation: "string", length: 5 },
    { id: 26, key: "rssi", required: false, validation: "numberString", length: 5 },
    { id: 27, key: "sync_timestamp", required: false, validation: "number", length: 10 },
    { id: 28, key: "reading_timestamp", required: false, validation: "number", length: 10 },
    { id: 29, key: "battery", required: false, validation: "numberString", length: 2 },
    { id: 30, key: "temp", required: false, validation: "numberString", length: 5 },
    { id: 31, key: "humidity", required: false, validation: "numberString", length: 5 },
    { id: 32, key: "co2", required: false, validation: "numberString", length: 5 },
    { id: 33, key: "ssid", required: true, validation: "string", length: 35 },
    { id: 34, key: "password", required: true, validation: "string", length: 35 },
    { id: 35, key: "pm1Offset", required: false, validation: "numberString", length: 2 },
    { id: 36, key: "pm2_5Offset", required: false, validation: "numberString", length: 2 },
    { id: 37, key: "pm10Offset", required: false, validation: "numberString", length: 2 },
    { id: 38, key: "pressureOffset", required: false, validation: "numberString", length: 2 },
    { id: 39, key: "coOffset", required: false, validation: "numberString", length: 7 },
    { id: 40, key: "coRo", required: false, validation: "numberString", length: 7 },
    { id: 41, key: "no2Offset", required: false, validation: "numberString", length: 7 },
    { id: 42, key: "no2Ro", required: false, validation: "numberString", length: 7 },
    { id: 43, key: "o3Offset", required: false, validation: "numberString", length: 7 },
    { id: 44, key: "tvocOffset", required: false, validation: "numberString", length: 7 },
    { id: 45, key: "organizationId", required: false, validation: "string", length: 5 },
    { id: 46, key: "nh3Offset", required: false, validation: "numberString", length: 7 },
    { id: 47, key: "nh3Ro", required: false, validation: "numberString", length: 7 },
    { id: 48, key: "noiseOffset", required: false, validation: "numberString", length: 2 },
    { id: 49, key: "luxOffset", required: false, validation: "numberString", length: 3 },
    { id: 50, key: "occupancyOffset", required: false, validation: "numberString", length: 10 },
    { id: 51, key: "pressure", required: false, validation: "numberString", length: 4 },
    { id: 52, key: "pm1", required: false, validation: "numberString", length: 3 },
    { id: 53, key: "pm2_5", required: false, validation: "numberString", length: 3 },
    { id: 54, key: "pm10", required: false, validation: "numberString", length: 3 },
    { id: 55, key: "tvoc", required: false, validation: "numberString", length: 7 },
    { id: 56, key: "no2", required: false, validation: "numberString", length: 7 },
    { id: 57, key: "co", required: false, validation: "numberString", length: 7 },
    { id: 58, key: "o3", required: false, validation: "numberString", length: 7 },
    { id: 59, key: "nh3", required: false, validation: "numberString", length: 7 },
    { id: 60, key: "audioDba", required: false, validation: "numberString", length: 3 },
    { id: 61, key: "audioPeakDba", required: false, validation: "numberString", length: 3 },
    { id: 62, key: "occupancyStatus", required: false, validation: "string", length: 10 },
    { id: 63, key: "light", required: false, validation: "numberString", length: 5 },
    { id: 64, key: "espFwVersion", required: false, validation: "string", length: 7 },
    { id: 65, key: "communicationMode", required: true, validation: "number", length: 2 },
    { id: 66, key: "peopleCount", required: false, validation: "number", length: 2 }
  ];
  
  useEffect(()=>{
    getTemplate(userDetails.session,userDetails.org_id).then(res=>{
      if(res.status===200)   {
        SetLoader(false)
        // let template=[...listTemplate];
        let apiTemp = res.data.map((el,index)=>{
          console.log("formatting",el,{id:index+1,name:el.template_title,value:el.template_value})
          return ({id:index+1,name:el.template_title,value:el.template_value})
        })
        apiTemp.push({id:100000,name:"Custom",type:"default",value:"custom"})
        SetListTemplate(apiTemp)
      }
    })
  },[])

   const handleSuccessClose = () => {
    setSuccessModalVisible(false);
  };
  // localStorage.removeItem('nfcTemplate')


  useEffect(() => {
    // Set up the message event listener to handle messages from the native side
    const handleMessage = (event) => {
    
      
      const message = event.data;
      
      if (message && message.type === 'nfcWriteSuccess') {
        handleWriteNFC(finalValue)
      }

      if(message && message.type === 'nfcMessage'){
        SetTitleMessage(message.data);
      }
      if (message && message.type === 'nfcScanCancelled') {
         setActions({
              scan: null,
              write: null
            })
      }
      if(message && message.type === 'deviceType'){
        document.body.classlist.add('iosdevice')
      }
    };

    window.addEventListener('message', handleMessage);

    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, [finalValue]);



  const handleSave = () => {
    let previousItem =[...listTemplate]
    let newItem= {type:"",name:title,value:finalValue,id: parseInt(previousItem.length +1) };

    let requestBody={
      "org_id" : userDetails.org_id,
      "template_title" : title,
      "template_value" : finalValue,
      "session":userDetails.session
    }
    SetLoader(true);
    
    createTemplate(requestBody).then(res=>{
      if(res.status===200){
        setSuccessModalVisible(false);
        SetLoader(false);
        SetListTemplate((prev)=>[...prev,newItem]);
        SetModalTitle(false);
        setSecondModalVisible(true);

      }else{
        SetLoader(false);
        SetModalSaveError(true);
      }
    })


    // previousItem.push({type:"",name:title,value:finalValue,id:previousItem.length +1})
    // console.log("previousItem",previousItem)
    // localStorage.setItem('nfcTemplate',JSON.stringify(previousItem));
    
  };

  const handleGeneralFieldChange = (field, value) => {
    dispatch({ type: `UPDATE_GENERAL_${field.toUpperCase()}`, payload: value });
  };

  const handleCalibrationFieldChange = (field, value) => {
    dispatch({ type: `UPDATE_CALIBRATION_${field.toUpperCase()}`, payload: value });
  };

  const handleThresholdFieldChange = (field, value) => {
    dispatch({ type: `UPDATE_THRESHOLD_${field.toUpperCase()}`, payload: value });
  };
   const onHandleAction = (actions) =>{
    setActions({...actions});
  }

  function validation(obj,value){
    //console.log("validation",obj,value);
    let length = obj.length;
    let type= obj.validation;
    let field = obj.key;
    let hex = new RegExp(`^[0-9A-Fa-f]{${length}}$`); 
    let no = new RegExp(`^\\d{${length}}$`); 
    let noString = new RegExp(`^-?\\d{${length}}$`); 
    // console.log("field" ,field,obj,value,length,!isNaN(value), value.toString().length <= length )
    if((obj.key =="ssid" || obj.key==="password") && (lorwanState.communicationMode ==="02" || lorwanState.communicationMode ==="") ){
      return true
    }else if ((obj.key =="ssid" || obj.key==="password") && value.length > 0){
      return true
    }
    else if(!obj.required){
      return true
    }
    else if ((type === "hex" && hex.test(value) && value.length <= length)  ) {
      return true
    } else if ((type === "number" && !isNaN(value) && value!=="" && value.toString().length <= length )) {
      
      return true
    }else if(field ==='rxChannel' && value!=="" && value.toString().length <= length){
      
      return true
    } else if ((type === "numberString" && (value.includes("+") || value.includes("-")) && value.length <= length) ) {
      return true
    }else{
      return false
    } 
    
  }

  function onChangeTemplate(label,value){
    console.log("onChangeTemplate e ",value,label);
    setTemplateSelected(label);
     if(value.label==="custom"){
      readWriteDataHandler("","custom");

      
     }else{
      readWriteDataHandler(value.label);
     } 
  }


  function readWriteDataHandler(inputString,type){
        let arr=inputString.split(",");
        let allField={}
        console.log("arr",arr)
        for(let i=0; i<arr.length;i++){
            let val = arr[i].split(":");
            let key = sequnece.find(el=>el.id == val[0]);

            console.log("key",key?.key);
            if(key && key.key!=="deviceEui"){
                console.log("key.key",key.key,val)               
                allField[key.key] = val[1];
            }
            if(key&&( key.key==="ssid"|| key.key==="password" ) ){
              allField[key.key] = extractSSID(val[1]);
            }
            

            if(key && key.key==="adrmode"){
              if(val[1]===1 || val[1]===0){
                allField[key.key] = val[1];
              }else{
                allField[key.key] = 0;
              }
              
            }
            
        }
        if(allField['adrmode']=== undefined){
          allField['adrmode']=0
        }else{
          console.log("adr key exist",allField['adrmode'])
        }


        allField['deviceEui'] = lorwanState.deviceEui;
        if(type && type=="custom"){

          dispatch({ type: `UPDATE_LORWAN_all`, payload: 
          { ...generalState,
              deviceEui:generalState.deviceEui?generalState.deviceEui:"",
              scanned:true,
              appEui: generalState.appEui?generalState.appEui:'0000000000000000',
              appPort:generalState.appPort?generalState.appPort: "002",
              joinType: generalState.joinType?generalState.joinType:'',
              networkSessionKey:generalState.networkSessionKey?generalState.networkSessionKey: '',
              applicationSessionKey: generalState.applicationSessionKey?generalState.applicationSessionKey:'',
              applicationKey:generalState.applicationKey?generalState.applicationKey:'',
              rx2DataRate:generalState.rx2DataRate?generalState.rx2DataRate: '',
              rx2Frequency: generalState.rx2Frequency?generalState.rx2Frequency:'',
              spreadingFactor: generalState.spreadingFactor?generalState.spreadingFactor:'',
              txPower: generalState.txPower?generalState.txPower:'',
              workMode:generalState.workMode?generalState.workMode:"",
              region:generalState.region?generalState.region:"0",
              rxChannel:generalState.rxChannel?generalState.rxChannel:9,
              reportingInterval:generalState.reportingInterval?generalState.reportingInterval:"10",
              offsetTemp:generalState.offsetTemp?generalState.offsetTemp:"+00",
              offsetHumidity:generalState.offsetHumidity?generalState.offsetHumidity:"+00",
              offsetCo2:generalState.offsetCo2?generalState.offsetCo2:"+0000",
              status:false,
              co2:generalState.co2?generalState.co2:"0000",
              humidity:generalState.humidity?generalState.humidity:"00",
              temp:generalState.temp?generalState.temp:"00",
              battery:generalState.battery?generalState.battery:"00",
} });
        }else{
          dispatch({ type: `UPDATE_LORWAN_all`, payload: allField });
        }
        
        
        setTimeout(function(){
                // navigate('/setting');
        },100)
  }

  function savedDataTemplate(typeOperation){
const now = new Date();

// Get the UTC timestamp
const utcTimestamp = parseInt((now.getTime())/1000);



    SetTitle('');
    let gatewaySetting={};
    gatewaySetting=lorwanState;
    let value="";
    let seq=[];
    let errorCount=0;

      seq = [...sequnece]

    console.log("seq",seq,[...sequnece]);
    let er={}
    for(let i=0;i<seq.length;i++){
      if(!validation(seq[i],lorwanState[seq[i].key])){
        er[seq[i].key] = "Field is required or invalid"
        errorCount++;
      }
      if(seq[i].key === "sync_timestamp" ){
        value+=seq[i].id+":"+utcTimestamp+",";
      }else if(seq[i].key === "ssid" || seq[i].key === "password"){
        value+=seq[i].id+":"+ (lorwanState[seq[i].key].toString().length).toString().padStart(2,"0")+"."+lorwanState[seq[i].key].toString().padEnd(32,"*")+","; ;
      }
      else if(seq[i].key === "peopleCount"){
        value+=seq[i].id+":"+lorwanState[seq[i].key];
      }else{
        value+=seq[i].id+":"+lorwanState[seq[i].key]+",";
      }
    }
    SetError(er);
    console.log("value",value,er,errorCount);

// SetModalTitle(true);
    if(errorCount==0){

        if(typeOperation ==="saveTemplate"){
          SetFinalValue(value);
          SetModalTitle(true);
          

        }else{
          SetFinalValue(value);
          console.log("final Value",value);
          SetTitleMessage('');

          if(window.ReactNativeWebView){
            window.ReactNativeWebView.postMessage(JSON.stringify({ type: 'writeNFC',data:value }));
          }

          ///after scuess it should pass
          // handleWriteNFC(value)
          SetSettingValue('')
          onHandleAction({scan: null, write: 'scaning'});

          //navigate('/')          
        }
    }else{
      SetErrorModal(true);
    }




  }

  function writeSucess(){
       onHandleAction({scan: null, write: null})       
  }



 const handleWriteNFC = async (value) => {  
  SetSettingValue(value);  
  SetLoader(true);    
  SetLogger({});
  console.log(lorwanState.model_number)
  const keyValuePairs = value.split(',');

// Create an object to store the key-value pairs
const dataObject = {};
keyValuePairs.forEach(pair => {
    const [key, value] = pair.split(':');
    let keyName = sequnece.find(el=>el.id == key);
    
    if(keyName){
      dataObject[keyName.key] = value;
    }
});
if(window.ReactNativeWebView){
            window.ReactNativeWebView.postMessage(JSON.stringify({ type: 'dataObject',data:dataObject }));
          }

  let requestBody ={
     "device_serial": dataObject.deviceEui.startsWith('0005')?dataObject.deviceEui.substring(2,dataObject.deviceEui.length):dataObject.deviceEui.startsWith('05')?dataObject.deviceEui:`05${dataObject.deviceEui}`,
    "no2_Ro": dataObject.no2Ro,
    "co_Ro": dataObject.coRo,
    "o3_Ro": "0",
    "nh3_Ro":dataObject.nh3Ro ,
    "temperature": dataObject.offsetTemp,
    "humidity": dataObject.offsetHumidity,
    "co2": dataObject.offsetCo2,
    "o3": dataObject.o3Offset,
    "no2": dataObject.no2Offset,
    "co": dataObject.coOffset,
    "pressure": dataObject.pressureOffset,
    "pm1": dataObject.pm1Offset,
    "pm2_5": dataObject.pm2_5Offset,
    "pm10": dataObject.pm10Offset,
    "nh3": dataObject.nh3Offset,
    "tvoc": dataObject.tvocOffset,
    "noise": dataObject.noiseOffset,
    "lux": dataObject.luxOffset,
    "occupancy": dataObject.occupancyOffset,
    "mode": dataObject.communicationMode,
    "session": 'Safe123456789'
}

SetLoader(false);    

if(window.ReactNativeWebView){
            window.ReactNativeWebView.postMessage(JSON.stringify({ type: 'requestbody',data:requestBody }));
          }
    updateDeviceOffset(requestBody).then(res=>{
      if(res.status===200){
        SetLoader(false);   
        
        SetLogger(requestBody);
         if(window.ReactNativeWebView){
            window.ReactNativeWebView.postMessage(JSON.stringify({ type: 'apiUpdateResponseIf',data:res }));
          }

        setSuccessModalVisibleWarn(true);  
        
        setSuccessModalVisible(true);
        onHandleAction({scan: null, write: null})       
        
      }else {
        if(window.ReactNativeWebView){
            window.ReactNativeWebView.postMessage(JSON.stringify({ type: 'apiUpdateResponseELSE',data:res }));
          }
        SetLoader(false);
        SetLogger(requestBody);
        setSuccessModalVisibleError(true);    
        setSuccessModalVisible(true);
        onHandleAction({scan: null, write: null})       
      }
    })
};

const handleSecondModalClose = () => {
    setSecondModalVisible(false);
  };

  




  return (
    <div className="App">
      <div className="container">

        {loader?<div className="loaderArea">
              <div className="loaderInner">  
                <Spin indicator={<LoadingOutlined style={{ fontSize: 78 }} spin />} />
            </div>
          </div>:""}
          
       <Row gutter={16} style={{marginTop:20}} className='bg-white bg-white-header'>
        <Col span={24}><h4>Select Template</h4></Col>
        </Row>
        
        <Row gutter={16} className='bg-white'>
        <Col span={24}>
          <div className="settingfield settingfield2"> 
            
            <Select
              value={selectTemplate}
              onChange={(e,label) => onChangeTemplate(e,label)}
              style={{width:"100%",height:"40px"}}
            > 
              {
          listTemplate.map(el=>{
            return <Option value={el.id} key={"dorpdown"+el.id} label={el.value}>{el.name}</Option>
          })}
           </Select>
          </div>
        </Col>
        </Row>{
       selectTemplate==""?"":<Collapse accordion defaultActiveKey={"network"}
        expandIcon={({ isActive }) => isActive?<MinusOutlined />:<PlusOutlined />}
        style={{marginTop:20}}
      >
      
        <Panel header="Communication" key="network">
          <NetworkSetting error={error} />
        </Panel>
        <Panel header="General" key="general">
          <GeneralTab error={error} />
        </Panel>
        <Panel header="Sensor Calibration" key="calibration">
          <CalibrationSetting error={error} />
        </Panel>
        <Panel header="Sesnor Reading Offset" key="offset">
          <OffsetSetting error={error} />
        </Panel>

      </Collapse>}


      {/* <WriteLanding message={nfcSettingData} writeSucess={writeSucess} title={titleMessage}/> */}
        <ActionsContext.Provider value={actionsValue}>
          {write && <WriteLanding message={nfcSettingData} writeSucess={writeSucess} title={titleMessage}/>}
        </ActionsContext.Provider>

        <div>
        </div>



    {selectTemplate==""?"":<div className="App-container-bottom button-2" style={{position:"relative",padding:"15px 0px",marginBottom:15}}>       
          <button className="btn btn  btn-transparent"  onClick={()=>savedDataTemplate('saveTemplate')}>Save As Template</button>
          <button onClick={()=>savedDataTemplate()} style={{marginTop:"10px"}} className="btn btn-transparent">Write</button>
        </div>}

    </div>

   <Modal
        title="Error"
        open={errorModal}
        onCancel={()=>SetErrorModal(false)}
        footer={[
          <Button key="close" onClick={()=>SetErrorModal(false)}>
            Close
          </Button>,
        ]}
      >
        <div className="errorSmaller">
        {Object.entries(error).map(([key, value]) => (
    <Row
      gutter={16}
      style={{ marginBottom: 5, paddingBottom: 5, borderBottom: "1px solid #ddd" }}
      key={key}
    >
      <Col span={8} style={{color:"#a22626",fontSize:10}}>{key}</Col>
      <Col span={16} style={{fontSize:10}}>{value}</Col>
    </Row>
  ))}
        </div>
      </Modal>
    <Modal
        title="Success Message"
        open={successModalVisible}
        onCancel={()=>handleSuccessClose()}
        footer={[
          <Button key="close" onClick={()=>handleSuccessClose()}>
            Close
          </Button>,
          <Button key="save" type="primary" onClick={()=>{SetModalTitle(true);setSuccessModalVisible(false);}}>
            Save
          </Button>,
        ]}
      >
         {successModalVisibleError?<span style={{color:"red"}}>Updated successfully, but data unable to sync with cloud. Please repeat the Update</span> :'Updated successfully and data sync with cloud.'}
      </Modal>



        <Modal
        title="Save Template"
        open={modalTitle}
        onCancel={()=>SetModalTitle(false)}

        footer={[
          <Button key="close" onClick={()=>SetModalTitle(false)}>
            Close
          </Button>,
          <Button key="save" type="primary" onClick={()=>handleSave()}>
            Save
          </Button>,
        ]}
      >
    <Form>
      
      <Row gutter={16}>
        <Col span={24}>
          <Form.Item className='settingfield' label="Template Title">
            <Input
              value={title}
              onChange={(e) => SetTitle(e.target.value)}
            />
          </Form.Item>
        </Col>
        </Row>
        </Form>       
      </Modal>

    <Modal
        title="Data Saved"
        open={secondModalVisible}
        onCancel={()=>handleSecondModalClose()}
        footer={[
          <Button key="close" onClick={()=>handleSecondModalClose()}>
            Close
          </Button>,
        ]}
      >
        Data Saved!
      </Modal>
    <Modal
        title="Failed to Saved"
        open={modalSaveError}
        onCancel={()=>SetModalSaveError(false)}
        footer={[
          <Button key="close" onClick={()=>SetModalSaveError(false)}>
            Close
          </Button>,
        ]}
      >
        <span style={{color:"#9a1c1c"}}>Failed to save, please try again after some time</span>
      </Modal>


   </div> 
  );
}

export default Settings;
